
import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ProjectSurveyTitle from '@/pages/project/components/survey-title.vue';
import ProjectDatetime from '@/pages/project/components/survey-period.vue';
import ProjectDemoGraphics, { DemoGraphic } from '@/pages/project/components/survey-demo-graphics.vue';
import ProjectSurveySpecificData from '@/pages/project/components/survey-specific-data.vue';
import ProjectSurveyDevice, { Device } from '@/pages/project/components/survey-device.vue';
import ProjectSurveyBrowser, { Browser } from '@/pages/project/components/survey-browser.vue';
import ProjectSurveyManagers from '@/pages/project/components/survey-managers.vue';
import { InvalidMessages, ValidationMixin } from '@/mixins/validation-mixin';
import CreatedNav from '@/pages/project/created/created-nav.vue';
import { CreatedMixin } from '@/mixins/created-mixin';
import RegistrationStep from '@/pages/project/components/registration-step.vue';


interface LoadResponse {
  demoGraphic: DemoGraphic,
  country: string[],
  estimateIncidenceRate: number,
  estimateLengthOfInterview: number,
  completeCount: number,
  device: Device,
  options: { preventDuplicates: boolean },
  supplier: string[],
  title: string,
  startDate: string,
  endDate: string,
  category: { [key: string]: boolean },
  browser: Browser,
  manager: string[],
}

@Component({
  components: {
    RegistrationStep,
    CreatedNav,
    ProjectSurveyTitle,
    ProjectDatetime,
    ProjectDemoGraphics,
    ProjectSurveySpecificData,
    ProjectSurveyDevice,
    ProjectSurveyBrowser,
    ProjectSurveyManagers,
  },
})
export default class CreateInfo extends mixins(Vue, CreatedMixin, ValidationMixin) {
  private title = '';
  private startDate = '';
  private endDate = '';
  private demoGraphic: DemoGraphic | null = null;
  private country: string[] = [];
  private estimateIncidenceRate = 0;
  private estimateLengthOfInterview = 0;
  private completeCount = 0;
  private cpi = 0;
  private currency = '';
  private device: Device | null = null;
  private options: { preventDuplicates: boolean } = { preventDuplicates: false };
  private supplier: string[] = [];
  private browser: Browser | null = null;
  private manager: string[] = [];

  async created() {
    this.loading = true;
    await this.load();
    await this.mixinsLoad();
    this.loading = false;
  }

  async load() {
    this.loading = true;
    try {
      const { result, data, message } = await this.axios.get(`/project/${ this.surveyId }`);
      if (!result) throw message;

      const {
        title,
        startDate,
        endDate,
        demoGraphic,
        country,
        estimateIncidenceRate,
        estimateLengthOfInterview,
        completeCount,
        device,
        options,
        supplier,
        browser,
        manager,
      } = data as LoadResponse;
      this.title = title;
      this.startDate = startDate;
      this.endDate = endDate;
      this.demoGraphic = demoGraphic;
      this.country = country;
      this.estimateLengthOfInterview = estimateLengthOfInterview;
      this.estimateIncidenceRate = estimateIncidenceRate;
      this.completeCount = completeCount;
      this.device = device;
      this.options = options;
      this.supplier = Object.keys(supplier || {});
      this.browser = browser;
      this.manager = manager.filter(r => r !== this.getUserId);
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  async submit() {
    this.proc(true);
    try {
      const {
        title,
        startDate,
        endDate,
        device,
        options,
        browser,
        manager,
      } = this;

      const { result, message } = await this.axios.patch(`/project/${ this.surveyId }`, {
        title,
        startDate,
        endDate,
        device,
        options,
        browser,
        manager,
      });

      if (message) this.validationMessages(message, result)

      this.invalidMessages.splice(0, this.invalidMessages.length);

      for (const [key, value] of Object.entries(message as InvalidMessages[])) {
        Vue.set(this.invalidMessages, key, value);
      }
    } catch (e) {
      this.validationMessages(e);
    }
    this.proc(false);
  }
}
