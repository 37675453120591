import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class CreatedMixin extends Vue {
  query = '';
  status = '';

  async mixinsLoad() {
    this.$store.commit('setProjectStatus', '');
    this.query = Object.entries(this.$route.query).map(([k, v]) => `${ k }=${ v }`).join('&');
    try {
      const { result, data, message } = await this.axios(`/project/${ this.surveyId }`);
      if (!result) throw message;
      const { status } = data;
      this.status = status;
      this.$store.commit('setProjectStatus', status);
      return data;
    } catch (e) {
      this.validationMessages(e);
    }
  }

  get regStep() {
    switch (this.$store.getters.projectStatus) {
      case 'ON':
      case 'PAUSE':
        return 6;
      case 'END':
      case 'CANCEL':
        return 7;
      case 'CREATED':
        return 5;
      default:
        return 0;
    }
  }
}
