
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Socket } from 'vue-socket.io-extended';
import { SOCKET_EVENTS } from '@/enum/enums';
@Component({})
export default class CollectionProgress extends Vue {
  private progress = 0;
  @Socket(SOCKET_EVENTS.COLLECTION_MADE)
  collectionMade({ surveyId }: { surveyId: string }): void {
    console.log(`COLLECTION MADE ${surveyId}`);
    this.progress = 0;
  }
  @Socket(SOCKET_EVENTS.COLLECTION_MADE_PROGRESS)
  collectionMadeProgress({ surveyId, per }: { surveyId: string; per: number }): void {
    if (this.surveyId === surveyId) this.progress = per;
  }
}
