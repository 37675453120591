
import { Vue, Component, Prop } from 'vue-property-decorator'
import ProjectStatus from "../components/project-status.vue";
import { INVOICE_STATUS, PROJECT_STATUS, USER_LEVEL } from '@/enum/enums';
import CollectionProgressComponent from '@/components/collection-progress.vue';
@Component({
  components: { ProjectStatus, CollectionProgressComponent }
})
export default class CreatedNav extends Vue{
  @Prop() query!: string;

  links = [
    {
      text: 'Home',
      href: `/project/created/home/`
    },
    {
      text: 'Info & Edit',
      href: `/project/created/info/`
    },
    {
      text: 'Redirects',
      href: `/project/created/urls/`
    },
    {
      text: 'Profiling & Quota',
      href: `/project/created/quota/`
    },
    {
      text: 'Sampling',
      href: `/project/created/sampling/`
    },
    {
      text: 'Respondent Log & View',
      href: `/project/created/respondent/`
    },
  ];
  title = '';
  surveyNo = '';
  status = '';
  my = false;
  invoiceStatus = '';

  created() {
    this.load();
  }

  async load() {
    this.loading = true;
    // await new Promise((resolve) => setTimeout(resolve, 2500));
    try {
      const { result, data, message } = await this.axios.get(`/project/${ this.surveyId }`);
      if (!result) throw message
      const { title, surveyNo, status, manager, statusChangeAvailable, invoiceStatus } = data as { title: string, surveyNo: string, status: PROJECT_STATUS, manager: string[], statusChangeAvailable?: boolean, invoiceStatus: INVOICE_STATUS };
      this.title = title;
      this.surveyNo = surveyNo;
      this.status = status;
      this.my = !!manager.find(r => r === this.getUserId);
      this.invoiceStatus = invoiceStatus;
      if (!statusChangeAvailable) this.my = false;
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  changeStatus(status: PROJECT_STATUS) {
    this.status = status;
    this.$emit('changeStatus', status);
    // this.status = status;
  }



  get path() {
    const { path, params } = this.$route;
    const { surveyId } = params
    return path.replace(surveyId, '')
  }
}
